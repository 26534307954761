import React, { useEffect, useState } from "react";

import "./index.css";
import loading from "../../assets/loading.svg";
import ApiServices from "../../services/apiServices";
import { toast } from "react-toastify";
export default function ListarCupons({ chave, setChave, setDadosLoja }) {
  const [listaUID, setListaUID] = useState([]);
  const [todosCupons, setTodosCupons] = useState([]);
  const [trava, setTrava] = useState(true);

  async function fnIdQrCode(chave) {
    var objEnvio = {
      accessKey: chave,
      identifyClient: "8398BF84-BC58-4C56-A35A-31E3ADC97D38",
    };

    try {
      let response = await ApiServices.apiPost("receipt", objEnvio);

      var chaveLimpa = response?.data.resultValue.replaceAll("-", "");
      console.log("chaveLimpa", chaveLimpa.length);
      if (chaveLimpa.length === 32) {
        setListaUID((prev) => [...prev, response?.data.resultValue]);
      } else {
        return toast.error(response?.data.message);
      }
      return toast.success("Aguarde o processamento.");
    } catch (err) {
      console?.error("err", err);
    } finally {
    }
  }

  useEffect(() => {
    if (chave.length === 44) {
      fnIdQrCode(chave);
    }
  }, [chave]);

  useEffect(() => {
    const fetchReceiptStatus = async (chave) => {
      try {
        const response = await ApiServices.apiGet("receipt/" + chave);
        return response.data.resultValue;
      } catch (err) {
        console.error("err", err);
        return null;
      }
    };

    const fetchData = async () => {
      if (listaUID.length > 0) {
        const temp = await Promise.all(
          listaUID.map((chave) => fetchReceiptStatus(chave))
        );
        console.log("temp", temp);
        setTodosCupons(temp.filter((data) => data !== null));
        setTrava(false);
      }
    };

    const interval = setInterval(() => {
      const allApproved = todosCupons.every((e) => e.state == "Aprovada");
      if (!allApproved || trava) {
        fetchData();
      }
    }, 6000);
    return () => clearInterval(interval);
  }, [listaUID]);

  // useEffect(() => {
  //   // setTodosCupons([
  //   //   {
  //   //     state: "Aprovada",
  //   //     companyName: "Supermercado A",
  //   //     issueDate: "01/08/2023 10:30:00",
  //   //     items: [
  //   //       { description: "Maçãs", value: 2.99 },
  //   //       { description: "Leite", value: 3.49 },
  //   //       { description: "Pão", value: 1.99 },
  //   //       { description: "Ovos", value: 4.79 },
  //   //       { description: "Cereal", value: 3.25 },
  //   //       { description: "Maçãs", value: 2.99 },
  //   //       { description: "Leite", value: 3.49 },
  //   //       { description: "Pão", value: 1.99 },
  //   //       { description: "Ovos", value: 4.79 },
  //   //       { description: "Cereal", value: 3.25 },
  //   //       { description: "Maçãs", value: 2.99 },
  //   //       { description: "Leite", value: 3.49 },
  //   //       { description: "Pão", value: 1.99 },
  //   //       { description: "Ovos", value: 4.79 },
  //   //       { description: "Cereal", value: 3.25 },
  //   //       { description: "Maçãs", value: 2.99 },
  //   //       { description: "Leite", value: 3.49 },
  //   //       { description: "Pão", value: 1.99 },
  //   //       { description: "Ovos", value: 4.79 },
  //   //       { description: "Cereal", value: 3.25 },
  //   //     ],
  //   //   },
  //   //   {
  //   //     state: "Aprovada",
  //   //     companyName: "Supermercado B",
  //   //     issueDate: "02/08/2023 09:45:00",
  //   //     items: [
  //   //       { description: "Arroz", value: 5.99 },
  //   //       { description: "Feijão", value: 3.49 },
  //   //       { description: "Carne", value: 8.99 },
  //   //       { description: "Salada", value: 2.5 },
  //   //       { description: "Molho", value: 1.75 },
  //   //     ],
  //   //   },
  //   //   {
  //   //     state: "Aguardando",
  //   //     companyName: "Supermercado C",
  //   //     issueDate: "03/08/2023 11:15:00",
  //   //     items: [
  //   //       { description: "Queijo", value: 4.99 },
  //   //       { description: "Presunto", value: 3.25 },
  //   //       { description: "Pão de Forma", value: 2.49 },
  //   //       { description: "Alface", value: 1.25 },
  //   //       { description: "Tomate", value: 0.99 },
  //   //     ],
  //   //   },
  //   //   {
  //   //     state: "Aprovada",
  //   //     companyName: "Supermercado D",
  //   //     issueDate: "04/08/2023 14:30:00",
  //   //     items: [
  //   //       { description: "Cerveja", value: 8.99 },
  //   //       { description: "Chips", value: 2.49 },
  //   //       { description: "Amendoim", value: 3.99 },
  //   //       { description: "Refrigerante", value: 2.25 },
  //   //       { description: "Pizza", value: 6.75 },
  //   //     ],
  //   //   },
  //   //   {
  //   //     state: "Aprovada",
  //   //     companyName: "Supermercado E",
  //   //     issueDate: "05/08/2023 16:20:00",
  //   //     items: [
  //   //       { description: "Biscoitos", value: 1.99 },
  //   //       { description: "Chocolates", value: 3.49 },
  //   //       { description: "Café", value: 5.99 },
  //   //       { description: "Açúcar", value: 1.5 },
  //   //       { description: "Leite Condensado", value: 2.75 },
  //   //     ],
  //   //   },
  //   //   {
  //   //     state: "Aprovada",
  //   //     companyName: "Supermercado F",
  //   //     issueDate: "06/08/2023 18:10:00",
  //   //     items: [
  //   //       { description: "Bananas", value: 1.29 },
  //   //       { description: "Morangos", value: 3.99 },
  //   //       { description: "Kiwi", value: 2.99 },
  //   //       { description: "Abacate", value: 1.75 },
  //   //       { description: "Uvas", value: 4.25 },
  //   //     ],
  //   //   },
  //   //   {
  //   //     state: "Aguardando",
  //   //     companyName: "Supermercado G",
  //   //     issueDate: "07/08/2023 20:00:00",
  //   //     items: [
  //   //       { description: "Frango", value: 6.99 },
  //   //       { description: "Peixe", value: 4.49 },
  //   //       { description: "Carne Suína", value: 7.99 },
  //   //       { description: "Cenoura", value: 0.75 },
  //   //       { description: "Brócolis", value: 2.25 },
  //   //     ],
  //   //   },
  //   // ]);
  // }, []);

  return (
    <div className="divCupons  w-100 ">
      {todosCupons.map((e, i) => (
        <div key={i} className="linhaEmpresa w-100 ">
          {e?.status == "Aprovado" ? (
            <div
              className="linhaAprovado mt-0"
              onClick={() => {
                setDadosLoja(e);
              }}
            >
              {e?.companyName ? e?.companyName : "### SEM NOME ###"}
            </div>
          ) : (
            <div className="linhaAguardando w-100 ">
              <div
                className="progress-bar progress-bar-striped bg-warning  linhaAguardando progress-bar-animated"
                role="progressbar"
                style={{
                  height: "100%",
                  color: "#000",
                  fontWeight: 700,
                }}
              >
                PROCESSANDO...
              </div>

              {/* <img width={20} className="ms-4" src={loading} alt="loading" /> */}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
