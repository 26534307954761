import React, { useEffect, useState } from "react";
import "./index.css";
import bg from "../../assets/logo.png";
import logo from "../../assets/logo.svg";
import cupom from "../../assets/cupom.png";

export default function Cupom({ dadosLoja }) {
  const [todosCupons, setTodosCupons] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);

  function fnValorTotal() {
    var total = 0;
    for (let i = 0; i < dadosLoja?.items?.length; i++) {
      total += dadosLoja.items[i].value;
    }
    setValorTotal("R$ " + total.toFixed(2));
  }

  useEffect(() => {
    fnValorTotal();
    console.log("dadosLoja-----------<<<", dadosLoja);
  }, [dadosLoja]);

  return (
    <>
      {dadosLoja?.items?.length > 0 ? (
        <div
          className="cupom-fiscal "
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div>
            <div className="text-center fw-bold fs-5">
              <b>CUPOM FISCAL</b>
            </div>
            <div className="cabecario">
              <div className="titulo espaco">
                {dadosLoja.companyName
                  ? dadosLoja.companyName
                  : "XXXXXXXXX XXXXX XXXXXXX"}
              </div>
              <div className="espaco">
                {dadosLoja.issueDate || "XX/XX/XXXX XX:XX:XX"}
              </div>
            </div>
            <hr />
            <div className="cupom">
              <div className="d-flex justify-content-between">
                <span>ITEM</span>
                <span>DESCRIÇÃO</span>
                <span>VALOR R$</span>
              </div>

              <div className="d-flex flex-column">
                {dadosLoja?.items?.map((e, i) => (
                  <div
                    key={i}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <span className="d-flex align-items-center">
                      <span className="itemLista">{i + 1}</span>
                      <span className="descricaoLista">{e.description}</span>
                    </span>
                    <span className="valorLista">{e.value.toFixed(2)}</span>
                  </div>
                ))}
              </div>

              <div className="d-flex align-items-center justify-content-between border-top pt-3">
                <span>TOTAL</span>
                <span>{valorTotal}</span>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center align-items-center w-100 border-top mt-3 pt-3">
                <div>
                  <div className="me-2">Tecnologia</div>
                  <img width={100} src={logo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="cupom-fiscal2 "
          style={{ backgroundImage: `url(${cupom})` }}
        ></div>
      )}
    </>
  );
}
