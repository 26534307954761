/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from "react";

import "./index.css";
import ApiServices from "../../services/apiServices";

import Mask from "../../services/mask";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import imgChave from "../../assets/img/imgChave.png";
import imgInfo from "../../assets/img/info.png";
import DigitarManualmente from "../../assets/img/DigitarManualmente.png";
import AnexarArquivo from "../../assets/img/AnexarArquivo.png";
import ScannearCupom from "../../assets/img/ScannearCupom.png";
import { toast } from "react-toastify";
import * as moment from "moment";

import scan from "../../assets/scan.svg";
import upload from "../../assets/upload.svg";
import QrReader from "react-qr-reader";

export default function QrCode({ setChave }) {
  const [modalModalChaveAcesso, setModalModalChaveAcesso] = useState(false);
  const [modalQrCode, setModalQrCode] = useState(false);
  const [modalDigitar, setModalDigitar] = useState(false);
  const [modalAnexarArquivo, setModalAnexarArquivo] = useState(false);
  const [modalScannearQrCode, setModalScannearQrCode] = useState(false);
  const [modalDigitarManualmente, setModalDigitarManualmente] = useState(false);
  const [campoChave, setCampoChave] = useState("");

  const [alertaCampo, setAlertaCampo] = useState({
    numeroNF: {
      status: "d-none",
      alerta: "Número Nf inválido",
    },
    dataEmissaoNF: {
      status: "d-none",
      alerta: "Data emissão NF inválido",
    },
    cnpjPrestador: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
    razaoSocialPrestador: {
      status: "d-none",
      alerta: "Data inválido",
    },
    valorTotalNF: {
      status: "d-none",
      alerta: "Valor total NF inválido",
    },
    seuCnpj: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
    chaveAcesso: {
      status: "d-none",
      alerta: "Cpnj inválido",
    },
  });

  const [notaFical, setNotaFical] = useState({
    numeroNF: "",
    dataEmissaoNF: "",
    cnpjPrestador: "",
    razaoSocialPrestador: "",
    valorTotalNF: "",
    seuCnpj: "",
    chaveAcesso: "",
    urlDocumentoNF: "",
  });

  const qrRef = useRef(null);

  const handleErrorFile = (error) => {
    toast.warning("Erro no Qr-Code");
  };
  const handleScanFile = (data, origem) => {
    if (data) {
      // O 'data' é o conteúdo do QR code
      setModalQrCode(false);
      const qrCodeParts = data.split("|");

      const dataQR = qrCodeParts[1]?.substr(0, 8);
      const valorQR = qrCodeParts[2];

      const year = dataQR?.slice(0, 4);
      const month = dataQR?.slice(4, 6);
      const day = dataQR?.slice(6, 8);

      const formattedDate = `${day}${month}${year}`;

      if (formattedDate.length === 8) {
        setNotaFical((prev) => ({
          ...prev,
          dataEmissaoNF: Mask.date(formattedDate),
        }));

        setNotaFical((prev) => ({
          ...prev,
          valorTotalNF: Mask.valor(valorQR),
        }));
      }

      const regex = /\d{44}/;
      const match = data.match(regex);

      if (match) {
        setNotaFical((prev) => ({
          ...prev,
          chaveAcesso: applyMask(match[0]),
        }));

        setNotaFical((prev) => ({
          ...prev,
          cnpjPrestador: Mask.cnpj(Mask.unMask(match[0]).substr(6, 14)),
        }));
        setChave(Mask.unMask(match[0]));
        toast.success("Chave de acesso scanneada com sucesso!");
      } else {
        toast.warning("Qr-Code inválido");
      }
    }
    if (data === null && origem === "arquivo") {
      toast.warning("Não foi possivel identificar um Qr-Code na imagem");
    }
  };

  const [mirror, setMirror] = useState(false); // To control mirroring
  const [availableCameras, setAvailableCameras] = useState([]); // To store available cameras
  const [selectedCamera, setSelectedCamera] = useState(""); // To manage the selected camera

  useEffect(() => {
    // Function to fetch and set available cameras here (see Step 2)
    fetchAvailableCameras();
  }, []);

  // Function to handle the mirroring button click
  const handleMirrorButtonClick = () => {
    setMirror((prevMirror) => !prevMirror);
  };

  // Function to fetch and set available cameras
  const fetchAvailableCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      if (cameras.length > 0) {
        setAvailableCameras(cameras);
        setSelectedCamera(cameras[0].deviceId); // Set the default selected camera to the first available camera
      }
    } catch (error) {
      console.error("Error fetching available cameras: ", error);
    }
  };

  // Function to handle camera selection from the dropdown
  const handleCameraChange = (event) => {
    setSelectedCamera(event.target.value);
  };

  // Function to handle error while scanning QR code

  function applyMask(numero) {
    // Remover traços e espaços do número, se houver
    const numeroLimpo = numero.replace(/[-\s]/g, "");

    // Inserir traços a cada 4 dígitos
    const partes = [];
    for (let i = 0; i < numeroLimpo.length; i += 4) {
      partes.push(numeroLimpo?.slice(i, i + 4));
    }

    // Juntar as partes com traços
    const numeroFormatado = partes.join("-");
    return numeroFormatado;
  }

  return (
    <>
      <>
        <QrReader
          ref={qrRef}
          delay={300}
          style={{ width: "100%" }}
          onError={handleErrorFile}
          onScan={(data) => {
            handleScanFile(data, "arquivo");
          }}
          legacyMode
          className="d-none "
        />
      </>

      <div className="container ">
        <div className="row">
          <div className="d-flex w-100">
            <div
              className="scan w-100 mr-3 "
              onClick={() => {
                setModalQrCode(true);
              }}
            >
              <img src={scan} className="mr-2" /> SCAN
            </div>
            <div
              className="scan w-100 ml-3"
              onClick={() => {
                qrRef.current.openImageDialog();
              }}
            >
              <img src={upload} className="mr-2" /> UPLOAD
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center w-100 m-3 ">
            <b className="text-center">ou</b>
          </div>
          <div
            className="scan w-100 text-center "
            onClick={() => {
              setModalDigitar(true);
            }}
          >
            INSERIR CÓDIGO MANUALMENTE
          </div>
          <input
            style={{ cursor: "default" }}
            className="scan2 w-100 mt-5 text-center "
            type="text"
            id="cnpj"
            placeholder="0000-0000-0000-0000-0000-0000-0000-0000-0000-0000-0000"
            value={notaFical.chaveAcesso}
            disabled
          />
        </div>
      </div>

      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      {/* DIGITAR */}
      <Dialog
        open={modalDigitar}
        maxWidth={"lg"}
        onClose={() => {
          setModalDigitar(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setModalDigitar(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="input-group d-flex flex-column ">
            <label className="form-label m0">
              Inserir 44 digitos da chave de acesso.
            </label>
            <div className="d-flex ">
              <input
                type="text"
                inputmode="numeric"
                className="form-control"
                placeholder="0000-0000-0000-0000-0000-0000-0000-0000-0000-0000-0000"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={campoChave}
                maxLength={54}
                onChange={(e) => {
                  alertaCampo.chaveAcesso.status = "d-none";
                  e.persist();
                  setCampoChave(applyMask(e.target.value.replace(/\D/g, "")));
                }}
              />

              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  {Mask.unMask(campoChave).length} / 44
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4 ">
            <button
              style={{ maxWidth: "200px" }}
              onClick={() => {
                setModalDigitar(false);
                setChave(Mask.unMask(campoChave));
                setNotaFical((prev) => ({
                  ...prev,
                  chaveAcesso: campoChave,
                }));
                setNotaFical((prev) => ({
                  ...prev,
                  cnpjPrestador: Mask.cnpj(
                    Mask.unMask(campoChave).substr(6, 14)
                  ),
                }));
              }}
              disabled={Mask.unMask(campoChave).length < 44}
              className="form-control my-3"
            >
              Confirmar
            </button>
          </div>
        </DialogContent>
      </Dialog>
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      {/* CAMERA */}
      <Dialog
        open={modalQrCode}
        maxWidth={"lg"}
        onClose={() => {
          setModalQrCode(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setModalQrCode(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="d-flex align-items-center justify-content-center flex-column  ">
          {/* Add the mirroring button */}
          <div className="row d-flex border justify-content-between p-3 ">
            <div className="col-md-5 m-0 p-0">
              <div>
                <label className="form-label m0">
                  Invereter no sentido horizontal
                </label>
                <button
                  style={{ minWidth: 200, height: 42 }}
                  className="form-control "
                  onClick={handleMirrorButtonClick}
                >
                  {!mirror ? "Espelhar" : "Não Espelhar"}
                </button>
              </div>
            </div>

            <div className="col-md-5 m-0 p-0 mb-3 ">
              <div>
                <label className="form-label m0">Selecionar Câmera</label>

                <select
                  className="form-control w-100 "
                  value={selectedCamera}
                  onChange={handleCameraChange}
                >
                  {availableCameras.map((camera) => (
                    <option key={camera.deviceId} value={camera.deviceId}>
                      {camera.label || `Camera ${camera.deviceId}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <QrReader
              delay={300}
              style={{
                width: "100%",
                // transform: mirror ? "scaleX(-1)" : "none",
              }} // Apply mirroring based on the "mirror" state
              onError={handleErrorFile}
              onScan={(data) => {
                handleScanFile(data, "scan");
              }}
              legacyMode={!modalQrCode}
              facingMode={mirror ? "user" : "environment"} // Specify the camera facing mode based on the selected camera
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
