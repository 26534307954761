// ###########################################
// # variaveis de configuração do sistema    #
// ###########################################

//PRODUCAO
const APIGLOBAL = "https://coletorgtw-api-stage.devpartner.com.br/api/v1/";
const ENDERECO = "https://devpartner-cep.azurewebsites.net/";
 

export {APIGLOBAL, ENDERECO};
