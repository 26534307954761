import React, { useState } from "react";
import QrCode from "../QrCode";
import ListarCupons from "../ListarCupons";
import Cupom from "../Cupom";
import Logo from "../../assets/logo.svg";
import header from "../../assets/header.png";
export default function Home() {
  const [chave, setChave] = useState("");
  const [dadosLoja, setDadosLoja] = useState({});

  return (
    <>
      <div>
        <div>
          <img width={"100%"} src={header} />
          <div className="container">
            <div className="row mb-5 mt-3">
              <div className="col-md-12 p-2">
                <QrCode setChave={setChave} />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex  d-flex justify-content-center justify-content-lg-start">
            <div className="col-lg-6 p-2 ">
              <ListarCupons
                chave={chave}
                setChave={setChave}
                setDadosLoja={setDadosLoja}
              />
            </div>
            <div className="col-lg-6 p-2 d-flex justify-content-center">
              <Cupom dadosLoja={dadosLoja} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
