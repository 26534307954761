// react dependencia
import React from "react";
import ReactDOM from "react-dom";
import Home from "./views/Home";
import Mensagem from "./components/Mensagem";

ReactDOM.render(
  <>
    <Mensagem />
    <Home />
  </>,
  document.getElementById("root")
);
